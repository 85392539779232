<template>
  <div>
    <b-modal ref="waitDialog" title="Please wait"
      header-bg-variant="info" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" ok-only>
      <span>Retrieving new report from server...</span>
    </b-modal>
    <b-modal ref="warningDialog" title="Report Error"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" ok-only>
      <span>{{warningMsg}}</span>
    </b-modal>
    <ReportCreator ref="reportCreator"/>
    <a ref="altReportLauncher" target="_blank"></a>
    <template v-if="entryToShow.study_uid != ''">
      <b-badge class="w-100 overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
    </template>
    <div>
      <b-badge v-if="showBadges" class="mt-1 d-none d-sm-block" :variant="statusVariant" show :size="buttonSize">{{statusBadge}}</b-badge>
    </div>
    <b-navbar v-if="entryToShow.study_uid != ''" type="dark" variant="dark">
      <b-navbar-nav>
        <b-dropdown class="ml-1" :size="buttonSize" title="Reports for Patient" :variant="(studyList.length>1)?'info':'secondary'">
          <template #button-content>
            <b-icon icon="journals"></b-icon>
          </template>
          <b-dropdown-item v-for="s in studyList" :key="s.study_uid" :disabled="s.report_status=='---'" @click="secondaryStudy(s.study_uid)">
            <b-icon v-if="s.study_uid == entryToShow.study_uid" icon="toggle-on" variant="info"/>
            <b-icon v-else icon="toggle-off" variant="secondary"/>
            {{(s.study_date_time == null) ? '---' : new Date(s.study_date_time).toLocaleString(locale)}}
            [{{s.modality.trim()}}]
            {{(s.report_status=='---')?'No Report':s.report_status}}
          </b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-button-group v-if="canDownload && (reportBuffer != '')" :size="buttonSize">
          <b-button @click="handleDownload('pdf')" variant="secondary" title="Download Report (PDF)" :disabled="pageCount == 0">
            <b-icon icon="file-ppt"></b-icon>
          </b-button>
          <b-button @click="handleDownload('docx')" variant="secondary" title="Download Report (DOCX)" :disabled="pageCount == 0">
            <b-icon icon="file-word"></b-icon>
          </b-button>
          <!-- +TODO+ vue-pdf print is experimental and not working well. Best to download/print.
          <b-button @click="handlePrint" variant="secondary" title="Print Report" :disabled="pageCount == 0">
            <b-icon icon="printer"></b-icon>
          </b-button>
          -->
        </b-button-group>
        <b-button-group v-if="createAllowed" :size="buttonSize">
          <b-button @click="handleCreateReport" variant="secondary" title="Create New Report">
            <b-icon icon="journal-plus"></b-icon>
          </b-button>
        </b-button-group>
        <b-button-group v-if="amendAllowed" :size="buttonSize">
          <b-button class="ml-2" @click="handleAmend" variant="warning" title="Amend Report">
            <b-icon icon="journal-check"></b-icon>
          </b-button>
        </b-button-group>
        <b-button-group v-if="editAllowed" :size="buttonSize">
          <b-button class="ml-2" @click="handleEdit" variant="secondary" title="Edit Report">
            <b-icon icon="pencil-square"></b-icon>
          </b-button>
        </b-button-group>
        <b-button-group v-if="showCloseButton" :size="buttonSize">
          <b-button class="ml-2" @click="handleClose" variant="secondary" title="Close Report">
            <b-icon icon="journal-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <div ref="alertsDiv">
      <template v-if="entryToShow.study_uid != ''">
        <b-alert v-if="numReports == 0" class="mt-2" variant="info" show>No report for study.</b-alert>
        <b-alert v-if="!reportError && (numReports > 0) && (reportBuffer == '')" class="mt-2" variant="info" show>
          <b-spinner class="ml-2" label="Loading..." variant="info"/> Loading report...
        </b-alert>
        <b-alert v-if="reportError" class="mt-2" variant="warning" show>
          PDF version not ready.
          <b-button class="ml-2" variant="primary" @click="show()">Retry</b-button>
        </b-alert>
      </template>
    </div>
    <div :style="pdfParentDivStyle">
      <div class="mt-1 pdfDiv" >
        <template v-for="i in pageCount">
          <div :key="i">
            <b-badge variant="secondary" show size="sm">Page {{i}}</b-badge>
            <pdf :src="reportBuffer" :page="i"></pdf>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid'
import broadcast from '../common/broadcast'
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import workflow from '../common/workflow'
import ReportCreator from './ReportCreator.vue'
import pdf from 'vue-pdf'

export default {
  name: 'reportViewer',
  components: {
    pdf,
    ReportCreator
  },
  data() {
    return {
      buttonSize: "sm",
      currentPage: 1,
      requestedPage: 1,
      pageCount: 0,
      reportBuffer: '',
      reportIds: [],
      reportList: [],
      reportError: false,
      studyList: [],
      entryToShow: webServices.getEmptyWorklistEntry(),
      viewerHeight: '100px',
      warningMsg: ''
    };
  },
  props: {
    "inReportWindow": Boolean,
    "showCloseButton": Boolean
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    activeStudyUid() {
      return (this.$store.state.activeComponent == 'ReportViewer') ? this.$store.state.activeStudyUid : ''
    },
    entry() {
      var entry = this.$store.getters.worklistEntryForStudy(this.activeStudyUid)
      entry = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
      return entry
    },
    locale() {
      return this.$store.state.locale
    },
    canDownload() {
      return permissions.hasPermission(this.entryToShow.group, permissions.CAN_DOWNLOAD_REPORT)
    },
    createAllowed() {
      var can_edit_reports = permissions.hasPermission(this.entryToShow.group, permissions.CAN_EDIT_REPORTS)
      return can_edit_reports && (this.entryToShow.study_uid != '') && (this.reportList != null) && (this.reportList.length == 0)
    },
    amendAllowed() {
      var can_edit_reports = permissions.hasPermission(this.entryToShow.group, permissions.CAN_EDIT_REPORTS)
      var can_sign_final = permissions.hasPermission(this.entryToShow.group, permissions.CAN_SIGN_FINAL)
      var is_final = false
      if ((this.reportId != '') && (this.reportList != null)) {
        for (var i=0; i < this.reportList.length; i++) {
          if (this.reportList[i].reportId == this.reportId) {
            is_final = (this.reportList[i].revisions[0].status === 'FINAL')
            is_final |= (this.reportList[i].revisions[0].status === 'AMENDED FINAL')
            break
          }
        }
      }
      return can_edit_reports && can_sign_final && is_final
    },
    editAllowed() {
      var can_edit_reports = permissions.hasPermission(this.entryToShow.group, permissions.CAN_EDIT_REPORTS)
      var is_not_final = false
      if ((this.reportId != '') && (this.reportList != null)) {
        for (var i=0; i < this.reportList.length; i++) {
          if (this.reportList[i].reportId == this.reportId) {
            is_not_final = (this.reportList[i].revisions[0].status !== 'FINAL')
            is_not_final &= (this.reportList[i].revisions[0].status !== 'AMENDED FINAL')
            break
          }
        }
      }
      return can_edit_reports && is_not_final
    },
    numReports() {
      return this.reportList.length
    },
    pdfParentDivStyle() {
      return "height: "+this.viewerHeight+"px;"
    },
    reportId() {
      return this.$store.state.reportId
    },
    showBadges() {
      return (this.reportBuffer != '')
    },
    statusBadge() {
      var status = ''
      if ((this.reportId != '') && (this.reportList != null)) {
        for (var i=0; i < this.reportList.length; i++) {
          if (this.reportList[i].reportId == this.reportId) {
            status = this.reportList[i].revisions[0].status
            status += ' (Reported by: '+this.reportList[i].revisions[0].userFullName+')'
            break
          }
        }
      }
      return status
    },
    statusVariant() {
      var status = ''
      if (this.reportId != '') {
        for (var i=0; i < this.reportList.length; i++) {
          if (this.reportList[i].reportId == this.reportId) {
            status = this.reportList[i].revisions[0].status
            break
          }
        }
      }
      return webServices.reportStatusToVariant(status, this.entryToShow.read, this.entryToShow.status)
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    }
  },
  watch: {
    entry(newVal/*, oldval*/) {
      // Patient/study information may have changed in entry.
      //
      if (this.$store.state.activeComponent == 'ReportViewer') {
        if ((newVal != null) && (newVal.study_uid == this.entryToShow.study_uid)) {
          this.entryToShow = newVal
        }
      }
    },
    activeStudyUid(newVal/*, oldVal*/) {
      if ((this.$store.state.activeComponent == 'ReportViewer')) {
        let closeStudyUid = this.entryToShow.study_uid
        const entry = this.$store.getters.worklistEntryForStudy(newVal)
        this.entryToShow = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
        this.show()
        if (closeStudyUid != '') {
          workflow.closeStudy(closeStudyUid, workflow.TARGET_REPORT_SIDEPANEL, this.$store.state.uid)
          .then(() => {
            this.$log.debug('closeStudy successful for report')
          })
          .catch(err => {
            this.displayToast(err.message, 'warning')
          })
        }
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
    }
  },
  methods: {
    show() {
      this.$store.commit('changeReportWindowsEditing', {
        'editing': false,
        'windowUid': this.$store.state.uid
      })
      this.reportBuffer = ''
      this.requestedPage = 1
      this.reportError = false
      this.reportIds = []
      this.reportList = []
      this.studyList = []
      this.pageCount = 0
      if (this.entryToShow.study_uid != '') {
        webServices.readReportList(this.entryToShow)
        .then(response => {
          if ((response != null) && (Object.keys(response).length > 0)) {
            this.$log.debug("Read report list.")
            this.$log.debug(response)
            this.handleResize()
            if ((response['group'] != this.entryToShow.group) || (response['study_uid'] != this.entryToShow.study_uid)) {
              throw new Error('unexpected group or study_uid in response')
            }
            this.reportList = []
            let rlKeys = Object.keys(response['reportList'])
            if (rlKeys.length > 0) {
              for (var i=0; i < rlKeys.length; i++) {
                if (this.reportIds.includes(rlKeys[i])) {
                  this.$log.debug('skipping duplicate reportId')
                }
                else {
                  this.reportList.push(response['reportList'][rlKeys[i]])
                  this.reportIds.push(rlKeys[i])
                }
              }
              const reportId = rlKeys[0]
              this.$store.commit('changeReportId', reportId)
              this.$log.debug("Getting report, reportId="+reportId) 
              webServices.readReport(this.entryToShow.study_uid, reportId, this.entryToShow.group, 'pdf')
              .then(response => {
                if ((response != null) && (reportId == this.reportId)) {
                  this.$log.debug("Read report, reportId="+reportId) 
                  this.reportBuffer = pdf.createLoadingTask({ data: response })
                  this.reportBuffer.promise.then(pdf => {
                    this.pageCount = pdf.numPages
                    this.handleResize()
                  })
                  webServices.updateStudyUserHistory(this.entryToShow, 'report')
                  .then(response => {
                    this.$log.debug("Updated user history for viewed report: "+JSON.stringify(response)) 
                  })
                  .catch(err => {
                    this.$log.debug("Error updating user history for viewed report: "+err.message) 
                  })
                }
                else {
                  this.$log.error("Report null or empty or another report has been requested.")
                  this.reportError = true
                }
              })
              .catch(err => {
                this.$log.warn("Error fetching report, err: "+err.message)
                if (this.$store.state.activeComponent == 'ReportViewer') {
                  let retryTimeout = 2000
                  this.$log.debug("Report viewer will retry in "+retryTimeout/1000+" seconds, err=: "+err)
                  var studyUid = this.entryToShow.study_uid
                  setTimeout(() => {
                    if ((studyUid == this.entryToShow.study_uid) && (this.$store.state.activeComponent == 'ReportViewer')) {
                      this.show()
                    }
                  }, retryTimeout);
                }
                else {
                  this.$log.debug("Skipping retry for report PDF")
                }
              })
            }
            else if (this.createAllowed) {
              // SP-212 - if no report, auto create one using default template.
              //
              this.autoCreateReport()
            }
          }
          else {
            this.$log.debug("Report list unexpected null or empty.")
          }
        })
        .catch(err => {
          this.$log.error("Error fetching report list: "+err.message)
        })

        webServices.readSinglePatientWorklist(this.entryToShow)
        .then(response => {
          // Make sure response includes current study.
          //
          for (var s = 0; s < response.length; s++) {
            if (response[s].study_uid == this.entryToShow.study_uid) {
              response.sort((a, b) => a.study_date_time - b.study_date_time)
              this.studyList = response
              break
            }
          }
        })
        .catch(err => {
          this.$log.error(`Unable to retrieve study list for current patient in report editor: ${err.message}`)
        })
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
    },
    autoCreateReport() {
      webServices.readReportTemplateList(this.entryToShow.group)
      .then(response => {
        if ((response != null) && (Object.keys(response).length > 0)) {
          this.$log.debug("Read report template list.")
          const defaultTemplates = response['templateList']['default']
          var defaultTemplateList = []
          for (var i=0; i<defaultTemplates.length; i++) {
            defaultTemplateList.push(defaultTemplates[i].name)
          }
          defaultTemplateList.sort()
          this.$log.debug(defaultTemplateList)
          if (defaultTemplateList.length > 0) {
            this.$refs.waitDialog.show()
            var templateName = defaultTemplateList[0];
            var foundForModality = false
            const modalities = this.entryToShow.modality.trim().split(" ")
            const modalityTemplateList = defaultTemplateList.filter(item => {
              for(var i=0; i < modalities.length; i++) {
                if (item.match(new RegExp(modalities[i])) != null) {
                  return item.match(new RegExp(modalities[i]))
                }
              }
              return null
            })
            this.$log.debug("modalityTemplateList.length="+modalityTemplateList.length)
            for (i=0; i < modalityTemplateList.length; i++) {
              if (modalityTemplateList[i].toUpperCase().startsWith('DEFAULT')) {
                templateName = modalityTemplateList[i]
                foundForModality = true
                this.$log.debug("Found default template for modality")
                break;
              }
            }
            if (!foundForModality) {
              for (i=0; i < defaultTemplateList.length; i++) {
                if (defaultTemplateList[i].toUpperCase().startsWith('DEFAULT')) {
                  templateName = defaultTemplateList[i]
                  this.$log.debug("Found any old default template")
                  break;
                }
              }
            }
            webServices.createReport('default', templateName, this.entryToShow, false)
            .then(response => {
              if ((response != null) && (Object.keys(response).length > 0) && (response.result == 'OK')) {
                this.$log.debug(response)
                this.$log.debug("Report created reportId=["+response.reportId+"].")

                // Update local worklist entry.
                //
                this.entryToShow.report_status = response.revision.status
                this.entryToShow.report_date_time = webServices.parseIsoTimestamp(response.revision.timestamp)
                this.entryToShow._rowVariant = webServices.reportStatusToVariant(response.revision.status, this.entryToShow.read, this.entryToShow.stat)

                // Update worklist entry...
                //
                webServices.readWorklist() // handle if running in worklist window
                broadcast.postMessage(broadcast.REFRESH_WORKLIST_MSG, this.$store.state.uid) // handle if in report window

                // Allow server a bit of time to generate sftp from docx.
                //
                setTimeout(() => {
                  this.$refs.waitDialog.hide()
                  this.$store.commit('changeReportId', response.reportId)
                  this.$store.commit('changeActiveComponent', 'ReportEditor')
                }, 1000);
              }
              else {
                this.$log.debug("Unexpected response from report endpoint.")
                this.$refs.waitDialog.hide()
                this.warningMsg = 'Report not created.'
                this.$refs.warningDialog.show()
              }
            })
            .catch(err => {
              this.$log.error("Error creating report: "+err)
              this.$refs.waitDialog.hide()
              this.warningMsg = 'Report not created.'
              this.$refs.warningDialog.show()
            })
          }
          else {
            this.$log.debug("Default report template not found.")
            this.warningMsg = "Default report template not found, report not created."
            this.$refs.warningDialog.show()
          }
        }
        else {
          this.$log.debug("Report template list null or empty.")
        }
      })
      .catch(err => {
        this.$log.error("Error fetching report template list: "+err)
      })
    },
    changeReport(report) {
      if ((report.reportId == this.reportId) && (this.reportBuffer != '')) {
        return;
      }

      this.$store.commit('changeReportId', report.reportId)
      this.reportBuffer = ''
      this.requestedPage = 1
      this.pageCount = 0
      this.reportError = false
      webServices.readReport(this.entryToShow.study_uid, report.reportId, this.entryToShow.group, 'pdf')
      .then(response => {
        if (response != null) {
          this.$log.debug("Read report.") 
          this.reportBuffer = { data: response }
          this.handleResize()
        }
        else {
          this.$log.debug("Report null or empty.")
          this.reportError = true
          this.handleResize()
        }
      })
      .catch(err => {
        this.$log.error("Error fetching report: "+err)
        this.reportError = true
        this.handleResize()
      })
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'INSPIRE PACS',
        variant: variant,
      })
    },
    handleCreateReport() {
      this.$refs.reportCreator.show(false)
    },
    handleClose() {
      // Only here if in side panel
      workflow.closeStudy(this.entryToShow.study_uid, workflow.TARGET_REPORT_SIDEPANEL, this.$store.state.uid)
      .then(() => {
        this.$log.debug('closeStudy successful for report')
      })
      .catch(err => {
        this.displayToast(err.message, 'warning')
      })
      this.$store.commit('changeActiveStudyUid', '')
      this.$store.commit('changeActiveComponent', '')
    },
    handleDownload(format) {
      this.displayToast("Report download started...", 'info')
      webServices.readReport(this.entryToShow.study_uid, this.reportId, this.entryToShow.group, format)
      .then(response => {
        var patientName = this.entryToShow.patient_name_dcm.replaceAll('^', '_')
        var patientId = this.entryToShow.patient_id
        var studyDate = this.entryToShow.study_date_dcm
        const reportName = "report_"+patientName+"_"+patientId+"_"+studyDate+"."+format
        var mimeType = (format == 'docx') ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/pdf'
        let reportBlob = new Blob([response], {type: mimeType})
        let reportUrl = URL.createObjectURL(reportBlob)
        this.$log.debug('Direct link to report: ' + reportUrl)
        this.$refs.altReportLauncher.href = reportUrl;
        this.$refs.altReportLauncher.setAttribute("download", reportName)
        this.$refs.altReportLauncher.click()
      })
      .catch(err => {
        this.$log.error(`Unable to download report: ${err.message}`)
        this.displayToast("Report download failed.", 'danger')
      })
    },
    handleAmend() {
      // Need to create a new draft revision for amendment.
      //
      this.$store.commit('changeActiveComponent', 'ReportEditor')
    },
    handleEdit() {
      this.$store.commit('changeActiveComponent', 'ReportEditor')
    },
    handlePrint() {
      var pageList = []
      for (var i=2; i <= this.pageCount; i++) { pageList.push(i); }
      this.$refs.pdf.print(100, pageList)
    },
    handleResize(/*event*/) {
      if (this.reportError || (this.reportList.length == 0) || (this.reportBuffer == '')) {
        this.viewerHeight = 0
      }
      else {
        this.viewerHeight = window.innerHeight - 175
      }
    },
    secondaryStudy(studyUid) {
      if (studyUid == this.entryToShow.study_uid) {
        return;
      }
      let secondaryEntry = null
      for (var s = 0; s < this.studyList.length; s++) {
        if (this.studyList[s].study_uid == studyUid) {
          secondaryEntry = this.studyList[s]
          break;
        }
      }
      if (secondaryEntry !== null) {
        this.$log.debug(`Requesting secondary report window for studyUid=${studyUid}`)
        if (this.inReportWindow || this.$store.state.inViewerWindow) {
          // Let primary window handle opening report window.
          //
          broadcast.postMessage(broadcast.OPEN_REPORT_WINDOW_MSG, {
            'entry': secondaryEntry,
            'studyUid': studyUid,
            'windowUid': uuid.v4()
          })
        }
        else {
          this.$store.commit('addSecondaryWorklistEntry', secondaryEntry)
          workflow.openSecondaryReport(studyUid)
        }
      }
      else {
        this.$log.warn(`Secondary entry not found for studyUid=${studyUid}`)
      }
    }
  }
};
</script>
<style scoped>
.pdfDiv {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>